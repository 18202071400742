import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import Menu from '../../components/menu';
import { useEffect } from 'preact/hooks';
import { storageHandler } from "../../util/storage_handler";

const Header = () => {
	useEffect(()=>{
		document.getElementById("main-menu-nav").style.width = "10%";
		//Set default to english
		if(!storageHandler.localStorageGet('lang')){
			storageHandler.localStorageSet('lang', 'en');
		}
		if(!storageHandler.localStorageGet('currency')){
			storageHandler.localStorageSet('currency', 'usd');
		}
	});
	const changeLanguage = (lang) => { 
		storageHandler.localStorageSet('lang', lang);
		location.reload();
	}
	const changeCurrency = (currency) => { 
		storageHandler.localStorageSet('currency', currency);
		location.reload();
	}
	return (<header class={style.header}>
		<h1>vEngine Admin</h1>
		<nav id="main-menu-nav">
			<Menu />
			{/* <Link activeClassName={style.active} href="/">Home</Link> */}
		</nav>
		
		<select name="currency" id="cur" onChange={e => changeCurrency(e.target.value)}>
			<option value="usd" selected={storageHandler.localStorageGet('currency') === 'usd'}>USD</option>
			<option value="aud" selected={storageHandler.localStorageGet('currency') === 'aud'}>AUD</option>
			<option value="cad" selected={storageHandler.localStorageGet('currency') === 'cad'}>CAD</option>
			<option value="eur" selected={storageHandler.localStorageGet('currency') === 'eur'}>EUR </option>
			<option value="jpy" selected={storageHandler.localStorageGet('currency') === 'jpy'}>JPY</option>
		</select>
		<select name="language" id="lang" onChange={e => changeLanguage(e.target.value)}>
			<option value="en" selected={storageHandler.localStorageGet('lang') === 'en'}>English</option>
			<option value="ja" selected={storageHandler.localStorageGet('lang') === 'ja'}>日本語 </option>
			<option value="tl" selected={storageHandler.localStorageGet('lang') === 'tl'}>Filipino</option>
		</select>
	</header>)
};

export default Header;