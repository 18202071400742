import { Router, route } from 'preact-router';

import Customers from '../routes/customers';
import Dashboard from '../routes/dashboard';
import Discounts from '../routes/discounts';
import Header from './header';
import Home from '../routes/home';
import Login from '../routes/login';
import OfferDetails from '../routes/offer-details';
import Offers from '../routes/offers';
import Orders from '../routes/orders';
import ProductDetails from '../routes/product-details';
import Products from '../routes/products';
import Reviews from '../routes/reviews';
import Settings from '../routes/settings';		
import { h } from 'preact';
import { storageHandler } from '../util/storage_handler';

// Code-splitting is automated for `routes` directory

async function isAuthenticated() {
	return typeof storageHandler.localStorageGet("token") !== "undefined";
}

const handleRoute = async e => {
    if (!e.url.includes("/login")) {
        const isAuthed = await isAuthenticated();
        if (!isAuthed) {
			route(`/login`, true);
		}
    } else {
		const isAuthed = await isAuthenticated();
		if (isAuthed) {
			route('/dashboard', true);
		}
	}
};


const App = () => (
	<div id="app">
		<Header />
		<Router onChange={handleRoute}>
			<Dashboard path="/" />
			<Dashboard path="/dashboard" />
			<Discounts path="/discounts" />
			<Customers path="/customers" />
			<Login path="/login/:shop_id" />
			<OfferDetails path="/offer/:id" />
			<Offers path="/offers" />
			<Orders path="/orders" />
			<Products path="/products" />
			<ProductDetails path="/product/:id" />
			<Reviews path="/reviews" />
			<Settings path="/settings"/>
		</Router>
	</div>
)

export default App;
